.rep-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
}

.message {
  width: 280px;
  position: relative;
  border: 1px solid #e5e5e5;
  padding: 0;
  border-radius: 10px;
  margin: 0 10px;
  background-color: #f8f8f8;
}

.generic-container {
  margin-bottom: 20px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.generic-msg {
  min-width: 400px;
  margin: 5px;
  padding: 20px;
}

.add-element {
  color: grey;
  width: 100px;
  text-align: center;
  padding-top: 80px;
}

.NewMessage-paper-300 {
  max-width: 600px;
  min-width: 400px;
  width: auto !important;
}
